<template>
    <div class="flex flex-col bg-whiteSmoke items-center pb-[80px] px-[100px]">
        <div class="flex flex-col gap-8">
            <div class="flex flex-col gap-6 items-center">
                <!--Title-->
                <div class="flex flex-col items-center text-center w-[300px] pt-[80px] text-4xl font-bold md:w-full">
                    <div>Order Smarter Not Harder.</div>
                </div>
                <div class="text-center w-[320px] flex-col items-center text-base md:w-[500px]">Save time and money with all your supplies in one app.</div>
            </div>
                <div class="grid grid-cols-1 md:grid-cols-3 gap-4 justify-items-center md:gap-6">
                    <div v-for="card in cards" :key="card.id">
                        <landing-card 
                            :icon="card.icon" 
                            :title="card.title" 
                            :description="card.desc" 
                            :id="card.id"
                        />
                    </div>
                </div>
            <div class="flex items-center justify-center">
                <button class="bg-primary text-white px-[121.5px] py-4 rounded-[29px] text-center text-semibold whitespace-nowrap" @click="navigateToForm">Get the App</button>
            </div>
        </div>
    </div>
</template>

<script>
import landingCard from '@/components/landingPage/Card.vue'
    export default{
        name: 'landingCards',
        data()
        {
            return{
                cards: [
                    {id: 1, icon: 'assets/lottie/OneOrder-CompetitvePricing.json', title:'Competitive Prices', desc: 'Get the best deals tailored to your business needs'},
                    {id: 2, icon: 'assets/lottie/Shopping.json', title:'Wide Variety of Categories', desc: 'Access a diverse range of supplies in one app.'},
                    {id: 3, icon: 'assets/lottie/Truck.json', title:'Reliable Delivery', desc: 'Count on timely and accurate order fulfillment.'},
                    {id: 4 ,icon:'assets/lottie/cash.json', title:'Post-Payment Terms', desc: 'Get the best deals tailored to your business needs'},
                    {id: 5 ,icon:'assets/lottie/saving.json', title:'Reports & Financial Analytics', desc:'Gain insights to optimize spending and track performance.'},
                    {id: 6 ,icon:'assets/lottie/chain.json', title:'My List', desc:'Quickly reorder your favorites with business customzied lists.'}
                ]
            }
        },
        components: {
            landingCard
        },
        methods: {
            navigateToForm() {
            this.$emit('navigate-to-form');
            }
        }
        
    }
</script>

<style>
</style>
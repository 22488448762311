<template>
   <div class="md:flex md:justify-center">
        <div class="flex flex-col gap-y-[80px] md:w-[1400px]" :style="{ fontFamily: 'Montserrat' }">
              <landing-header @navigate-to-form="scrollToForm"/>
              <div>
                <div class="flex flex-col gap-[80px] md:gap-[120px]">
                  <div >
                    <landing-hero @navigate-to-form="scrollToForm" class="pt-[160px] max-w-[1400px]" />
                  </div>
                  <landing-cards @navigate-to-form="scrollToForm" />
                </div>
                <div class="flex flex-col gap-[40px] px-10 md:pt-[40px]">
                    <landing-form-section ref="formSection"/>
                    <landing-footer />
                </div>
            </div>
        </div>
   </div>
</template>

<script>
import landingHeader from '@/components/landingPage/Header.vue'
import landingHero from '@/components/landingPage/Hero.vue'
import landingCards from '@/components/landingPage/Cards.vue'
import landingFormSection from '@/components/landingPage/FormSection.vue'
import landingFooter from '@/components/landingPage/Footer.vue'
export default{
    name: 'LandingPage',
    components: {
        landingHeader,
        landingHero,
        landingCards,
        landingFormSection,
        landingFooter
    },
    methods:{
        scrollToForm() {
      if (this.$refs.formSection && this.$refs.formSection.scrollToInnerForm) {
        this.$refs.formSection.scrollToInnerForm();
      } else {
        console.error('formSection ref or scrollToInnerForm method not found');
      }
    },
    }
}
</script>

<style>

html {
  scroll-behavior: smooth;
}
</style>
var render = function render(){var _vm=this,_c=_vm._self._c;return (!_vm.showDownload)?_c('div',[_c('div',{staticClass:"p-6 bg-whiteSmoke rounded-[16px]"},[_c('div',{staticClass:"flex flex-col gap-4 items-left"},[_c('div',{staticClass:"text-blackBg text-[21px] w-[230px] font-bold"},[_vm._v(" Select your business type ")]),_c('div',{staticClass:"flex flex-col gap-4 mb-6 items-left md:flex-row"},_vm._l((_vm.businessTypes),function(type){return _c('button',{key:type.id,class:[
                  'flex items-center w-[239px] h-[52px] justify-between px-5 rounded-lg transition-colors duration-300 md:w-[170px]',
                  _vm.selectedType === type.id
                  ? 'bg-blutTint text-blackBg border border-solid border-black'
                  : 'bg-white text-blackBg border border-solid border-gray'
              ],on:{"click":function($event){return _vm.selectType(type.id)}}},[_c('span',{staticClass:"flex-1 text-left"},[_vm._v(_vm._s(type.name))]),_c('span',[(_vm.selectedType != type.id)?_c('div',{staticClass:"w-[22px] h-[22px] border border-solid rounded-2xl"}):_c('div',[_vm._m(0,true)])])])}),0)]),_c('div',{staticClass:"flex"},[_c('button',{staticClass:"bg-primary text-white w-[239px] px-5 py-[18px] rounded-[29px] text-semibold",on:{"click":_vm.submit}},[_vm._m(1)])])])]):_c('div',[_c('landing-download')],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"w-[22px] h-[22px] border border-solid border-black rounded-2xl flex items-center justify-center"},[_c('div',{staticClass:"w-[11px] h-[11px] bg-black rounded-2xl"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex flex-row justify-between items-center"},[_c('span',[_vm._v("Get the App")]),_c('div',[_c('img',{attrs:{"src":require("../../assets/images/up-arrow.svg"),"alt":""}})])])
}]

export { render, staticRenderFns }
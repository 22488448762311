<template>
    <div class="flex flex-col items-center gap-[10px] pb-10 md:flex-row md:justify-between">
        <!--Logo-->
        <div class="flex">
            <img class="w-[125px] h-[17px]" src="../../assets//images/logo.svg" alt="Image">
        </div>
        <!--Text-->
        <div class="text-base">
            © 2024 OneOrder Technologies Inc.
        </div>
    </div>
</template>

<script>
export default {
    name: 'landingFooter',
};
</script>

<style>
</style>
<template>
  <div class="w-full">
    <div class="flex flex-col bg-white py-10 px-6 gap-4 rounded-[22px]">
      <div class="flex flex-col gap-4 md:flex-row md:items-start md:gap-4">
        <div class=" w-[30px] h-[30px] items-center justify-center">
            <div
                :key="id"
                @mouseover="lottieMouseOver(id)"
            >
            <lottie-player
                :ref="id"
                :src="icon"
                background="transparent"
                speed="1"
                class="items-center w-[40px] h-[40px]"
                :autoplay="true"
                :loop = "true"
            ></lottie-player>
            </div> 
        </div>
        <div class="text-[22px] font-bold h-[66px]">{{ title }}</div>
      </div>
        <div class="  text-lg md:w-full">{{ description }}</div>
    </div>
  </div>
</template>

<script>
export default{
    name: 'landingCard',
    props:{
        icon: String,
        title: String,
        description: String,
        id: Number,
    },
    methods: {
    lottieMouseOver(lottieId) {
      const refName = `lottie-${lottieId}`;
      const lottie = this.$refs[refName];
      if (lottie) {
        lottie.seek(0);
        lottie.play();
      } else {
        console.warn(`Lottie ref "${refName}" not found.`);
      }
    },
  },
}
</script>

<style>
</style>
<template>
  <div>
    <section
    class="scroll-area w-screen flex flex-col justify-center overflow-x-hidden py-10 m-0 md:h-0"
    >
        <vue-slick-carousel v-bind="settings">
        <div
          v-for="image in images"
          :key="image.id"
          class="!flex flex-col justify-center items-center"
        >
          <div class="flex justify-center flex-col items-center px-4 w-[100px]">
            <img
              :src="directory + image.src"
              :alt="image.alt"
              class="w-[90%] -z-20 max-h-[48px]"
            />
          </div>
        </div>
      </vue-slick-carousel>
    </section>
  </div>
</template>

<script>
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
export default{
    name: 'landingLogoCarousel',
    components: { VueSlickCarousel },
    props:{
        images: Array,
        directory: String,
    },
    data()
    {
        return{
        supplierImagesDirectory: "/assets/images/suppliers/laptop/",
        clientsImagesDirectory: "/assets/images/clients/laptop/",
        settings: {
            arrows: false,
            infinite: true,
            slidesToShow: this.isPhone ? 1 : 3,
            slidesToScroll: 1,
            autoplay: true,
            speed: 2000,
            autoplaySpeed: 0,
            cssEase: "linear",
            pauseOnHover: false,
            swipe: false,
            touchMove: false,
            draggable: false,
            variableWidth: true,
      },
            
        }
    }
}
</script>
